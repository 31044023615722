import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Toolbar from "@mui/material/Toolbar";
import LoaderOverlay from '../Loader/LoaderOverlay';
import PolicyBar from './PolicyBar';
import ToolboxPolicy from '../Toolbox/ToolboxPolicy';
import { useLocation } from 'react-router-dom';
import PolicyStack from './PolicyStack';
import PolicyTimeline from './PolicyTimeline';
import PolicyClaims from './PolicyClaims';
import PolicyConfirmSignedModal from './PolicyModals/PolicyConfirmSignedModal';
import PolicyActivateErrorModal from './PolicyModals/PolicyActivateErrorModal';

// Styles
import { LinkPrimary as linkPrimary } from '../../Styles/Base/Type';
import { PolicyReport } from './PolicyReport';

const TabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const Policy = props => {
  const [value, setValue] = React.useState(0);
  const [openConfirmPolicySignedModal, setOpenConfirmPolicySignedModal] = React.useState(false);
  const [openActivatePolicyErrorModal, setOpenActivatePolicyErrorModal] = React.useState(false);
  const location = useLocation();
  const {
    loaded,
    onModalMonitorOpen,
    status,
    statusTitle,
    statusPillTitle,
    history,
    claims,
    // Policy Stack
    basisOfSumInsured,
    coverages,
    currency,
    description,
    customerName,
    exclusions,
    title,
    totalSumInsured,
    inceptionDate,
    expiryDate,
    grossPremium,
    grossPremiumNumber,
    // Policy Stack ./end
    onDownload,
    handleActivate,
    policyId,
    policyScheduleDocumentId,
    premiumRate,
    issuer,
    intermediary,
    policyItems
  } = props;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOpenActivatePolicyModal = () => {
    setOpenConfirmPolicySignedModal(true);
  };

  const handleCloseActivatePolicyModal = () => {
    setOpenConfirmPolicySignedModal(false);
  };

  const handleActivatePolicy = () => {
    const isStartDateInFuture = new Date(inceptionDate).getTime() > new Date().getTime();
    handleCloseActivatePolicyModal();

    if (isStartDateInFuture) {
      handleActivate();
    } else {
      setOpenActivatePolicyErrorModal(true);
    }
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      paddingBottom: theme.spacing(6),
      overflowX: 'hidden',
      overflowY: 'auto',
    },
    main: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    link: linkPrimary(),
    divider: {
      opacity: 0.15,
    },
    cardContainer: {
      marginBottom: theme.spacing(3),
    },
    stripe: {
      backgroundColor: theme.palette.stripe,
      padding: `8px 14px`,
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
    },
    stripeTitle: {
      fontSize: 18,
      display: 'inline-block',
      marginRight: theme.spacing(3),
      fontWeight: 700
    },
    card: {
      paddingBottom: theme.spacing(3),
    },
    cardItem: {
      display: 'flex',
      justifyContent: 'space-between',
      '& > *': {
        padding: '8px 18px',
      }
    },
    spaced: {
      marginBottom: theme.spacing(2),
    },
    full: {
      width: '100%',
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tab = searchParams.get("tab");

    if (tab === "report") {
      setValue(3);
    }
  }, [location.search]);

  return (
    <Box className={classes.root}>
      <Toolbar />
      <ToolboxPolicy
        loaded={loaded}
        label="Policy details"
        variant={status}
        onModalMonitorOpen={onModalMonitorOpen}
        handleOpenActivatePolicyModal={handleOpenActivatePolicyModal}
      />
      <PolicyBar loaded={loaded} title={statusTitle} pillTitle={statusPillTitle} variant={status} />
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="Policy tabs"
        TabIndicatorProps={{
          className: classes.indicator,
        }}
      >
        <Tab label="Details" className={classes.tab} />
        <Tab label="History" disabled className={classes.tab} />
        <Tab label="Claims" className={classes.tab} />
        <Tab label="Report" className={classes.tab} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <PolicyStack
          basisOfSumInsured={basisOfSumInsured}
          inceptionDate={inceptionDate}
          expiryDate={expiryDate}
          premiumRate={premiumRate}
          grossPremium={grossPremium}
          grossPremiumNumber={grossPremiumNumber}
          coverages={coverages}
          currency={currency}
          customerName={customerName}
          exclusions={exclusions}
          onDownload={onDownload}
          policyScheduleDocumentId={policyScheduleDocumentId}
          status={status}
          title={title}
          totalSumInsured={totalSumInsured}
          loaded={loaded}
          policyId={policyId}
          intermediary={intermediary}
          issuer={issuer}
          description={description}
          policyItems={policyItems}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <PolicyTimeline items={history} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <PolicyClaims claims={claims} onDownload={onDownload} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <PolicyReport
          title={title}
          customerName={customerName}
          issuer={issuer}
          intermediary={intermediary}
          inceptionDate={inceptionDate}
          expiryDate={expiryDate}
          status={status}
          description={description}
          coverages={coverages}
          currency={currency}
        />
      </TabPanel>
      <LoaderOverlay open={props.backdropOpen} />
      <PolicyConfirmSignedModal
        open={openConfirmPolicySignedModal}
        onClose={handleCloseActivatePolicyModal}
        onConfirm={handleActivatePolicy}
      />
      <PolicyActivateErrorModal
        open={openActivatePolicyErrorModal}
        onClose={() => setOpenActivatePolicyErrorModal(false)}
      />
    </Box>
  );
};

export default Policy;
