import React from 'react';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 12,
    boxShadow: '0px 3px 12px 0px #0000001F',
    padding: theme.spacing(2),
    backgroundColor: 'white',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  amount: {
    fontSize: 48,
    fontWeight: 700,
    margin: 0,
    lineHeight: '65.37px'
  },
  title: {
    fontSize: 20,
    fontWeight: 700,
    margin: 0,
    lineHeight: '27.24px'
  },
  extra: {
    fontSize: 18,
    fontWeight: 700,
    color: (props) => props.extraColor,
    margin: 0,
    lineHeight: '24.51px',
    marginTop: 8,
  }
}));

const EventDetailsOverviewCard = ({ amount, title, extra, extraColor }) => {
  const classes = useStyles({ extraColor });
  
  return (
    <Box className={classes.root}>
      <Typography className={classes.amount}>{amount}</Typography>
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.extra}>{extra}</Typography>
    </Box>
  );
};

export default EventDetailsOverviewCard;
