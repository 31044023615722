import React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import makeStyles from '@mui/styles/makeStyles';
import EventDetailsOverviewCard from './EventDetailsOverviewCard';
import { Grid } from '@mui/material';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 12,
    boxShadow: '0px 3px 12px 0px #0000001F',
    padding: theme.spacing(2),
    backgroundColor: 'white',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
}));

export const EventDetailsOverviewCards = ({overviewCards = [], isLoading}) => {
  const classes = useStyles();

  return (
    <Grid container direction="row" rowGap={4} spacing={2} sx={{ mt: 2.5 }} mb={2} useFlexGap>
      {isLoading
        ? new Array(4).fill(0).map((_, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Box className={classes.root}>
                <Skeleton />
                <Skeleton height={80} />
              </Box>
            </Grid>
          ))
        : overviewCards.map((card) => (
            <Grid item xs={12} sm={6} md={3} key={card.title}>
              <EventDetailsOverviewCard {...card} />
            </Grid>
          ))}
    </Grid>
  );
}
